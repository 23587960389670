import React, { useContext, useEffect, useState } from "react"
import { Grid, IconButton, Typography } from "@material-ui/core"
import { RecipeCard } from "./RecipeCard"
import { RecipeCardDialog } from "./RecipeCardDialog"
import { ShadowContext } from "../../Shared/ShadowContext"
import { ChevronLeft, ChevronRight, FastForward, FastRewind } from "@material-ui/icons"

function getSpotlightText(spotlight) {
  switch (spotlight) {
    case "highestrated": {
      return "Highest Rated Recipes"
    }
    case "mostused": {
      return "Most Used Recipes"
    }
    case "leastused": {
      return "Least Used Recipes"
    }
    case "recentlyused": {
      return "Recently Used Recipes"
    }
    case "notrecentlyused": {
      return "Less Used Recipes"
    }
    case "newest": {
      return "Newest Recipes"
    }
    default: {
      break
    }
  }
}

/**
 * @param {{
 *  recipes: Array<import("../Types").Recipe>;
 *  recipeSelected?: (recipe: import("../Types").Recipe) => void;
 *  updateRecipe?: (recipe: import("../Types").Recipe) => void;
 *  spotlight?: string
 * }} props
 * @returns {JSX.Element | null}
 */
export const SpotlightRecipe = (props) => {
  const { recipes, spotlight } = props

  const [recipe, setRecipe] = useState(null)
  const { shadowRoot } = useContext(ShadowContext)

  const [viewRecipes, setViewRecipes] = useState([])

  const [page, setPage] = useState(0)
  const [pages, setPages] = useState(0)

  const itemsPerPage = 10

  useEffect(() => {
    setPages(Math.ceil(recipes.length / itemsPerPage) - 1)
    setPage(0)
  }, [recipes])

  useEffect(() => {
    const viewRecipes = recipes.slice(page * itemsPerPage, page * itemsPerPage + itemsPerPage)

    setViewRecipes(viewRecipes)
  }, [recipes, page])

  if (!spotlight) {
    return null
  }

  return (
    <Grid id="container" container spacing={2} style={{ margin: 0, width: "100%" }}>
      <Grid item xs={12}>
        <Typography variant="h4">{getSpotlightText(spotlight)}</Typography>
      </Grid>
      <div
        style={{
          overflow: "hidden",
          padding: 20,
          whiteSpace: "nowrap",
          overflowX: "auto",
          flexWrap: "nowrap",
          display: "flex",
        }}
      >
        {!recipes.length ? <Typography>No recipes here yet.</Typography> : null}
        {viewRecipes.map((recipe, index) => {
          const { recipeSelected, updateRecipe } = props

          return (
            <div key={index} style={{ width: 300, marginRight: 24, display: "inline-block", height: 450 }}>
              <RecipeCard
                recipe={recipe}
                recipeSelected={(recipe) => recipeSelected && recipeSelected(recipe)}
                updateRecipe={(recipe) => updateRecipe && updateRecipe(recipe)}
                expandClick={(recipe) => {
                  setRecipe(recipe)
                }}
              />
            </div>
          )
        })}
      </div>
      <Grid item xs={12}>
        <div style={{ float: "left" }}>
          <IconButton color="primary" disabled={page === 0} onClick={() => setPage(0)}>
            <FastRewind fontSize="large" />
          </IconButton>
          <IconButton color="primary" disabled={page === 0} onClick={() => setPage(page - 1)}>
            <ChevronLeft fontSize="large" />
          </IconButton>
        </div>

        <div style={{ float: "right" }}>
          <IconButton color="primary" disabled={page === pages} onClick={() => setPage(pages)}>
            <FastForward fontSize="large" />
          </IconButton>
          <IconButton color="primary" disabled={page === pages} onClick={() => setPage(page + 1)}>
            <ChevronRight fontSize="large" />
          </IconButton>
        </div>
      </Grid>
      {recipe ? (
        <RecipeCardDialog
          recipe={recipe}
          open={!!recipe}
          onClose={() => {
            setRecipe(null)
          }}
          shadowRoot={shadowRoot}
        />
      ) : null}
    </Grid>
  )
}
