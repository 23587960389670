import React from "react"
import ReactMaterialComponentBase from "../../Shared/ReactMaterialComponentBase"
import { SpotlightRecipe as ReactSpotlightRecipe } from "../React/SpotlightRecipe"

export default class SpotlightRecipe extends ReactMaterialComponentBase {
  static get observedAttributes() {
    return ["target", "spotlight"]
  }

  constructor() {
    super()

    /**
     * @type {Array<import("../Types").Recipe>}
     */
    this.recipes = []

    this.jsxRootComponent = () => (
      <ReactSpotlightRecipe
        recipes={this.recipes}
        updateRecipe={(recipe) => this.updateRecipe(recipe)}
        spotlight={this.spotlight}
        recipeSelected={(recipe) => this.recipeSelected(recipe)}
      />
    )

    this.target = this.getAttribute("target")

    this.getRecipes()
  }

  async attributeChangedCallback(name, oldValue, newValue) {
    if (!newValue) {
      return
    }

    if (name === "target") {
      this.target = newValue
    }

    if (name === "spotlight") {
      this.spotlight = newValue
    }

    await this.getRecipes()
  }

  async getRecipes() {
    if (!this.spotlight) {
      return
    }

    try {
      const token = await window.authorise()

      const getRecipesResponse = await fetch(`${this.target}/spotlight/${this.spotlight}`, {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      })

      const recipes = /** @type {Array<import("../Types").Recipe>} */ (await getRecipesResponse.json())

      this.recipes = recipes

      this.render()
    } catch (error) {
      this.onError && this.onError(error)
    }
  }

  async updateRecipe(recipe) {
    try {
      const token = await window.authorise()

      await fetch(`${this.target}/${recipe.id}`, {
        method: "PUT",
        headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" },
        body: JSON.stringify(recipe),
      })

      this.getRecipes()
    } catch (error) {
      this.onError && this.onError(error)
    }
  }

  recipeSelected(recipe) {
    this.dispatchEvent(
      new CustomEvent("recipe-selected", {
        composed: true,
        bubbles: true,
        detail: {
          recipe,
        },
      })
    )
  }
}

customElements.define("spotlight-recipe", SpotlightRecipe)
